import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { debounce } from 'lodash';

import tree, {
  treeCreation, treeInverse, treeSofaCreations, treeChairCreation,
} from './tree';
import pricing, { pricingInverse, metadata, upcyclingPricingInverse } from '../../services/pricing';
import {
  formatClothOrPiece, formatSlots,
  getClothesWithSuitPiecesAndSofaPartsSplitted, getCompleteFabrics,
} from '../../services/orderFormatting';
import { formatMask } from '../../services/maskFormatting';
import { pushToLayer } from '../../services/googleTagManager';
import { getZone } from '../../core/services/zipcode';
import routesMap from '../../Routes';

import withOrderContext from '../../withOrderContext';
import withCustomerContext from '../../withCustomerContext';
import { colors } from '../home/v3/styledComponents';
import {
  ComponentsContainer, MeetingInfosContainer, StyledLink, Title2,
} from './meetingInfosComponents';
import OrderSummaryContainer from './OrderSummaryContainer';
import callApi from '../../services/api';
import { getLocality, getDeliveryFees } from '../../services/zipcode';
import { isEmailValid, isPhoneValid, getValidEmail } from '../../services/checkingFormat';
import LogoButton from './LogoButton';
import tick from '../../assets/tick.png';
import Feedback from '../home/v3/Feedback';
import PopUpWaitTime from './PopUpWaitTime';
import PopUpLocality from './PopUpLocality';
import ContactInfos from './ContactInfos';
import feedbacks from './meetingInfosFeedbacks';
import {
  getInitState, getUpdatedFormInputsFromCustomer, getUpdatedFormInputsFromAddress, getUpdatedFormInputsBillingAddressFromAddress,
  getAddressPropsFromFormInputs, getCustomerProps, areAddressesIdentical, checkZipcodeFormInputAndUpdateFormInputs,
  checkEmailFormInputAndUpdateFormInputs, checkPhoneFormInputAndUpdateFormInputs, getEndPoint, getBillingAddressPropsFromFormInputs,
} from './meetingInfosData';
import PasswordForgottenModal from '../MyAccount/PasswordForgottenModal';
import customerFeedbacks from '../home/v3/customerFeedbacks';

function trackOrder(order, isFirstOrder, promoCode) {
  const zone = getZone(order.fullAddress.zipcode);
  pushToLayer({
    event: 'Order Sent',
    funnel_type: order.funnelType,
    area: zone && zone.id,
    order_id: order._id,
    promo_code: promoCode ?? '',
    is_first_order: !!isFirstOrder,
    is_logged_in: true,
    order_amount: Math.round(order.totalPaid * 100),
    user_id: typeof order.customer === 'object'
      ? order.customer._id
      : order.customer,
  });
}

const getTree = (cloth) => {
  if (cloth.selectedTypeOfWork === 'creation') {
    if (cloth.sofaPart) return treeSofaCreations;
    if (['crea_chai', 'crea_stoo'].includes(cloth.creationSlug)) return treeChairCreation;
    return treeCreation;
  }
  return tree;
};

const SpanLink = styled.span`
  text-decoration: underline;
  color: ${colors.navy};
  cursor: pointer;
`;

class MeetingInfos extends Component {
  constructor() {
    super();
    this.state = { ...getInitState() };
    this.submitOrderDebounced = debounce(this.submitOrder, 2000, { leading: true, trailing: false });
  }

  componentDidMount() {
    const { customerContext: { customer, initializeCustomerContext }, orderContext: { initialize }, isAdviceOrder } = this.props;
    if (localStorage.getItem('landingPage') === 'Advice' && !isAdviceOrder) {
      localStorage.removeItem('landingPage');
      localStorage.removeItem('landingPage_expiryKey');
      initialize();
    }
    if (customer !== undefined) {
      if (customer.isShadowAccount === true) {
        initializeCustomerContext();
      } else {
        this.setCustomer();
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { customerContext: { customer } } = this.props;
    if (!prevProps.customerContext.customer && !!customer) this.setCustomer();
  }

  onSelectAddress = (address) => {
    const { formInputs } = this.state;
    this.displayDeliveryFees(address.zipcode);
    this.setState({
      formInputs: { ...getUpdatedFormInputsFromAddress(formInputs, address) },
      selectedAddress: { ...address },
    });
  }

  getEmailValidityLabel = () => {
    const { formInputs: { email, firstname, lastname } } = this.state;
    const validEmail = getValidEmail(email, lastname, firstname);
    return (
      <span>
        Voulez-vous dire
        {' '}
        <SpanLink onClick={() => this.setEmail(validEmail)}>{validEmail}</SpanLink>
        {' '}
        :) ?
      </span>
    );
  };

  setEmail = (email) => {
    const { formInputs } = this.state;
    formInputs.email.value = email;
    formInputs.email.error = false;
    formInputs.email.hasError = false;
    formInputs.errorEmail.isActive = false;
    formInputs.errorEmailValidity.isActive = false;
    formInputs.errorEmailValidity.label = '';

    this.setState({ formInputs: { ...formInputs } }, this.checkEmail);
  }

  setCustomer = () => {
    const { customerContext: { customer } } = this.props;
    this.setIsAlreadyCustomer(true);
    this.setIsLoggedIn(true);
    this.displayAddressesList(true, () => {
      const { formInputs } = this.state;
      this.setFormInputs(getUpdatedFormInputsFromCustomer(formInputs, customer));
    });
  }

  setFormInputs = (formInputs, callback = () => {}) => {
    this.setState({ formInputs: { ...formInputs } }, callback);
  }

  setIsAlreadyCustomer = (isAlreadyCustomer) => {
    this.setState({ isAlreadyCustomer });
  }

  setIsLoggedIn = (isLoggedIn) => {
    this.setState({ isLoggedIn });
  }

  setFormInputValue = (event, listName) => {
    const { state } = this;
    const { target: { name, value } } = event;
    const newFormInputs = { ...state[listName] };
    if (name === 'zipcode') newFormInputs.locality.value = getLocality(value) ?? '';
    newFormInputs[name].value = value;
    if (listName === 'formInputsAlreadyCustomer' || name !== 'email') {
      this.setState({ [listName]: { ...newFormInputs } }, this.checkFormInputValue(name, listName, false));
    } else {
      this.setState({ [listName]: { ...newFormInputs } }, this.checkEmail);
    }
  }

  setIsAlreadyCustomerWhenEmailChecked = () => {
    const { formInputsAlreadyCustomer: formInputsAlreadyCustomerState, formInputs } = this.state;
    const formInputsAlreadyCustomer = { ...formInputsAlreadyCustomerState };
    formInputsAlreadyCustomer.email.value = formInputs.email.value;
    formInputsAlreadyCustomer.email.error = false;
    formInputsAlreadyCustomer.errorEmail.isActive = false;
    this.setState({
      isAlreadyCustomer: true,
      formInputsAlreadyCustomer: { ...formInputsAlreadyCustomer },
    });
  }

  setPopUpIsOpen = (popUpLocalityIsOpen) => {
    this.setState({ popUpLocalityIsOpen });
  }

  setCustomerAndOrderId = (customer, orderId) => {
    const { customerContext: { setCustomer }, orderContext: { setOrderId } } = this.props;
    setCustomer(customer);
    setOrderId(orderId);
  }

  setFormInputsForEmail = (isMoreActive = false) => {
    const { formInputs } = this.state;
    const newFormInputs = { ...formInputs };
    const callback = () => this.checkFormInputValue('email', 'formInputs', false);
    newFormInputs.email.more.isActive = isMoreActive;
    this.setFormInputs(newFormInputs, callback);
  }

  checkEmail = () => {
    const { formInputs } = this.state;
    const newFormInputs = { ...formInputs };
    const isValid = isEmailValid(newFormInputs.email.value);
    if (isValid) {
      callApi(`public/checkEmail/${formInputs.email.value}`, 'get').then(() => {
        this.setFormInputsForEmail(true);
      }).catch(() => {
        this.setFormInputsForEmail();
      });
    } else {
      this.setFormInputsForEmail();
    }
  }

  displayDeliveryFees = (zipcode) => {
    const { orderContext: { updateDeliveryFee }, isAdviceOrder } = this.props;
    const deliveryFees = getDeliveryFees(zipcode) ?? 0;
    this.setState({ deliveryFees });
    updateDeliveryFee(deliveryFees);
    const zone = getZone(zipcode);
    if (zone?.id) pushToLayer({ area: zone.id });
    if (!isAdviceOrder && deliveryFees === 15) {
      pushToLayer({ event: 'Order Funnel - Higher Fees Message Displayed', area: zone.id });
      this.setPopUpIsOpen(true);
    }
  }

  displayAddressesList = (mustSetAddress = false, callback = () => {}) => {
    const { customerContext: { customer } } = this.props;
    const addressesList = [{ ...customer.address, _id: 'initialAddress' }, ...customer.addresses].filter((address) => address.street);
    if (mustSetAddress && addressesList.length > 0) {
      const { formInputs } = this.state;
      this.setFormInputs(getUpdatedFormInputsFromAddress(formInputs, addressesList[0]));
    }
    const { selectedAddress } = this.state;
    this.displayDeliveryFees(selectedAddress.zipcode);
    this.setState({
      addressesList,
      selectedAddress: addressesList[0],
      isAddingNewAddress: !(addressesList && addressesList.length > 1),
    }, callback);
  }

  addAddress = () => {
    const { formInputs } = this.state;
    this.setIsAlreadyCustomer(true);
    const newFormInputs = getUpdatedFormInputsFromAddress(formInputs);
    this.setState({
      isAddingNewAddress: true,
      formInputs: { ...newFormInputs },
    });
  }

  login = ({ accessToken } = {}) => {
    const { formInputsAlreadyCustomer: { email, password } } = this.state;
    const request = accessToken
      ? { fbAccessToken: accessToken }
      : { email: email.value, password: password.value };
    callApi('login', 'post', request).then(({ customer }) => {
      const { formInputs } = this.state;
      const { customerContext: { setCustomer, setCustomerDiscountVouchers } } = this.props;
      const { _id, discountVouchers } = customer;

      const address = customer.address?.street ? customer.address : customer.addresses[0];
      pushToLayer({ event: 'Login', is_logged_in: true, user_id: _id });
      let newFormInputs = getUpdatedFormInputsFromCustomer(formInputs, customer);
      newFormInputs = getUpdatedFormInputsFromAddress(newFormInputs, address);

      setCustomer(customer);
      setCustomerDiscountVouchers(discountVouchers);
      this.displayAddressesList();
      this.setState({
        formInputs: { ...newFormInputs },
        isLoggedIn: true,
      }, () => Object.keys(newFormInputs).forEach((formInput) => !formInput.includes('error') && this.checkFormInputValue(formInput, 'formInputs')));
    }).catch(() => {
      const { formInputsAlreadyCustomer } = this.state;
      const newFormInputs = { ...formInputsAlreadyCustomer };
      newFormInputs.errorConnection.isActive = true;
      newFormInputs.email.error = undefined;
      this.setState({
        formInputsAlreadyCustomer: { ...newFormInputs },
        isLoggedIn: false,
      });
    });
  }

  createCustomerRequest = () => {
    const {
      formInputs, selectedAddress, isAlreadyCustomer, isAddingNewAddress, isSubscribingToNewsletter,
    } = this.state;
    const { customerContext: { customer } } = this.props;
    const addressFromForm = getAddressPropsFromFormInputs(formInputs);
    const mustUseExistingAddress = !(!isAlreadyCustomer || isAddingNewAddress);
    if (!mustUseExistingAddress) {
      const { addresses = [] } = customer ?? {};
      const address = addresses.find((addressIt) => areAddressesIdentical(addressIt, addressFromForm)) || addressFromForm;
      return { ...getCustomerProps(formInputs), address, hasSubscribedToNewsletter: isSubscribingToNewsletter };
    }
    return { ...getCustomerProps(customer), address: selectedAddress };
  }

  updateDeliveryFees = (deliveryFee) => {
    const { orderContext: { updateDeliveryFee } } = this.props;
    updateDeliveryFee(deliveryFee);
  }

  checkIsDone = () => {
    const { formInputs, formInputsBillingAddress, areBillingAddressAndRdvAddressIdentical } = this.state;
    const isMainFormDone = Object.values(formInputs)
      .reduce((acc, { isRequired, hasError }) => acc && (!isRequired || hasError === false), true);
    const isBillingFormDone = areBillingAddressAndRdvAddressIdentical || Object.values(formInputsBillingAddress)
      .reduce((acc, { isRequired, hasError }) => acc && (!isRequired || hasError === false), true);
    return isMainFormDone && isBillingFormDone;
  }

  checkFormInputValue = (name, listName, mustDefineError = true) => {
    let newFormInputs = { ...this.state[listName] };
    if (name === 'zipcode') {
      newFormInputs = checkZipcodeFormInputAndUpdateFormInputs(newFormInputs, mustDefineError, listName !== 'formInputsBillingAddress');
      if (listName !== 'formInputsBillingAddress') this.displayDeliveryFees(newFormInputs.zipcode.value);
    } else if (name === 'email') {
      const { isAlreadyCustomer } = this.state;
      newFormInputs = checkEmailFormInputAndUpdateFormInputs(newFormInputs, mustDefineError, isAlreadyCustomer, this.getEmailValidityLabel);
    } else if (name === 'phone') {
      newFormInputs = checkPhoneFormInputAndUpdateFormInputs(newFormInputs, mustDefineError);
    } else if (newFormInputs[name].isRequired) {
      if (mustDefineError) {
        newFormInputs[name].error = newFormInputs[name].value === '';
      }
      newFormInputs[name].hasError = newFormInputs[name].value === '';
    } else {
      newFormInputs[name].error = newFormInputs[name].value === '' ? undefined : false;
      newFormInputs[name].hasError = false;
    }
    this.setState({ [listName]: { ...newFormInputs } }, () => this.checkIsDone());
  }

  toggleAreBillingAddressAndRdvAddressIdentical = () => {
    const { customerContext: { customer } } = this.props;
    const { areBillingAddressAndRdvAddressIdentical, formInputsBillingAddress } = this.state;
    this.setState({
      areBillingAddressAndRdvAddressIdentical: !areBillingAddressAndRdvAddressIdentical,
      formInputsBillingAddress: getUpdatedFormInputsBillingAddressFromAddress(formInputsBillingAddress, customer?.billingAddress || undefined),
    });
  }

  submitOrder = () => {
    this.setState({ popUpWaitTimeIsOpen: true });
    const { orderContext: { promoCode }, isAdviceOrder } = this.props;
    const { isLoggedIn, isAddingNewAddress } = this.state;
    const request = this.createRequest();
    const endpoint = getEndPoint(isLoggedIn, isAddingNewAddress, request.order.address);
    callApi(endpoint, 'post', request)
      .then((res) => {
        trackOrder(res.order, res.isFirstOrder, promoCode && promoCode.code, isAdviceOrder);
        this.setCustomerAndOrderId(res.customer, res.order.id);
        navigate(routesMap.Step4.url);
      })
      .catch((response) => {
        this.setState({ popUpWaitTimeIsOpen: false });
        if (typeof Raven !== 'undefined') {
          Raven.captureException(JSON.stringify(response)); // eslint-disable-line
        } else {
          // eslint-disable-next-line no-console
          console.error(response);
        }
      });
  }

  createRequest = () => {
    const { customerContext: { customer: customerProps } } = this.props;
    const {
      formInputs, isAddingNewAddress, isSubscribingToNewsletter, areBillingAddressAndRdvAddressIdentical, formInputsBillingAddress,
    } = this.state;
    const customer = this.createCustomerRequest();
    const order = this.createOrderRequest(customer);
    const customerHasSubscribedToNewsletter = customerProps?.hasSubscribedToNewsletter;
    const hasSubscribedToNewsletter = customerHasSubscribedToNewsletter || isSubscribingToNewsletter;
    const request = { order, hasSubscribedToNewsletter, phone: formInputs.phone.value || undefined };
    if (isAddingNewAddress && !order.address) {
      request.address = customer.address;
    } else {
      request.customer = customer;
    }
    if (!areBillingAddressAndRdvAddressIdentical) request.billingAddress = getBillingAddressPropsFromFormInputs(formInputsBillingAddress);
    return request;
  }

  createOrderRequest = (customer) => {
    const {
      orderContext: {
        clothes: clothesProps, slots, rdv3ClicksOrder, promoCode, funnelType,
      },
      isAdviceOrder,
    } = this.props;
    const clothes = getClothesWithSuitPiecesAndSofaPartsSplitted(clothesProps);
    const rdv1Slots = formatSlots(slots);
    const landingPage = localStorage.getItem('landingPage');
    const brand = localStorage.getItem('brand');

    return {
      rdv1: rdv1Slots[0],
      rdv1Slots,
      fabrics: isAdviceOrder ? ['advice'] : getCompleteFabrics(clothes, rdv3ClicksOrder),
      promoCode: promoCode?.code,
      minOrderAmount: 0,
      deliveryFee: getDeliveryFees(customer?.address?.zipcode),
      pricingVersion: metadata.version,
      clothes: this.createOrderClothes(),
      address: customer?.address?._id, // eslint-disable-line no-underscore-dangle
      funnelType: isAdviceOrder ? 'advice' : funnelType,
      funnelInfo: {
        clothes,
        rdv3ClicksOrder,
        customerHasSelectedFur: clothes.length !== 0 ? !!clothes.find(({ isFurSelected }) => isFurSelected) : rdv3ClicksOrder.isFurSelected,
      },
      ...landingPage && { landingPage },
      ...brand && { brand },
    };
  }

  createOrderClothes3Clicks() {
    const { orderContext: { rdv3ClicksOrder } } = this.props;
    if (rdv3ClicksOrder.nbMasks > 0) return [formatMask(rdv3ClicksOrder.nbMasks)];
    return [];
  }

  createOrderClothesStandard() {
    const { orderContext: { nbMasks, clothes: clothesProps, promoCode } } = this.props;
    const clothes = getClothesWithSuitPiecesAndSofaPartsSplitted(clothesProps);
    const brand = promoCode?.brand;
    const formattedClothes = clothes.map((cloth) => formatClothOrPiece(
      getTree(cloth), treeInverse, pricing, pricingInverse, upcyclingPricingInverse, cloth, brand, true,
    ));
    if (nbMasks > 0) formattedClothes.push(formatMask(nbMasks));
    return formattedClothes;
  }

  createOrderClothes() {
    const { orderContext: { funnelType } } = this.props;
    return funnelType === 'lite' ? this.createOrderClothes3Clicks() : this.createOrderClothesStandard();
  }

  renderContactInfos() {
    const {
      isAlreadyCustomer, isLoggedIn, areBillingAddressAndRdvAddressIdentical,
      formInputs, formInputsAlreadyCustomer, formInputsBillingAddress,
      selectedAddress, isAddingNewAddress, addressesList, isSubscribingToNewsletter,
    } = this.state;
    const { customerContext: { customer } } = this.props;

    const customerHasPhone = !!customer?.phone && isPhoneValid(customer.phone);
    const customerHasSubscribedToNewsletter = customer?.hasSubscribedToNewsletter || false;
    const inputList = (isAlreadyCustomer && !isLoggedIn) ? formInputsAlreadyCustomer : formInputs;
    return (
      <ContactInfos
        isAlreadyCustomer={isAlreadyCustomer}
        isLoggedIn={isLoggedIn}
        addingNewAddress={isAddingNewAddress}
        addressesList={addressesList}
        selectedAddress={selectedAddress}
        customerHasPhone={customerHasPhone}
        customerHasSubscribedToNewsletter={customerHasSubscribedToNewsletter}
        isSubscribingToNewsletter={isSubscribingToNewsletter}
        toggleSubscribeToNewsletter={() => this.setState({ isSubscribingToNewsletter: !isSubscribingToNewsletter })}
        formInputs={formInputs}
        inputList={inputList}
        setIsAlreadyCustomer={this.setIsAlreadyCustomer}
        login={this.login}
        onSelectAddress={this.onSelectAddress}
        addAddress={this.addAddress}
        checkFormInputValue={this.checkFormInputValue}
        setFormInputValue={this.setFormInputValue}
        setIsAlreadyCustomerWhenEmailChecked={this.setIsAlreadyCustomerWhenEmailChecked}
        showPasswordForgottenModal={() => this.setState({ isPasswordForgotten: true })}
        areBillingAddressAndRdvAddressIdentical={areBillingAddressAndRdvAddressIdentical}
        toggleAreBillingAddressAndRdvAddressIdentical={this.toggleAreBillingAddressAndRdvAddressIdentical}
        formInputsBillingAddress={formInputsBillingAddress}
      />
    );
  }

  renderButtons() {
    const { isAlreadyCustomer, isLoggedIn } = this.state;
    const { orderContext: { clothes }, isAdviceOrder, city } = this.props;

    const clothesWithCustomerFeedback = clothes.filter(({ selectedCloth, selectedPiece }) => (
      !!customerFeedbacks[selectedCloth] || !!customerFeedbacks[selectedPiece]
    )).map(({ selectedCloth, selectedPiece }) => selectedCloth || selectedPiece);
    const customerFeedback = customerFeedbacks[clothesWithCustomerFeedback[0]];

    const isDone = this.checkIsDone();
    return ((!isAlreadyCustomer || (isAlreadyCustomer && isLoggedIn)) && (
      <div>
        <ComponentsContainer marginBottom marginTop>
          <LogoButton onClick={() => navigate(routesMap.Step2.url, { state: { isAdviceOrder, city } })} back noZoom>Disponibilités</LogoButton>
          <StyledLink onClick={() => isDone ? this.submitOrderDebounced() : null}>
            <LogoButton
              backgroundColor={colors.navy}
              textColor={colors.white}
              back
              logo={tick}
              neverHideLogo
              mobileFirst
              isAvailable={isDone}
            >
              Confirmer le RDV
            </LogoButton>
          </StyledLink>
        </ComponentsContainer>

        <ComponentsContainer>
          <Feedback isTransparent feedback={customerFeedback || feedbacks[0]} />
        </ComponentsContainer>
      </div>
    )
    );
  }

  render() {
    const {
      isAlreadyCustomer, isLoggedIn, deliveryFee, formInputs,
      popUpLocalityIsOpen, popUpWaitTimeIsOpen, isPasswordForgotten,
    } = this.state;
    const { orderContext: { clothes, slots }, isAdviceOrder } = this.props;
    return (
      <MeetingInfosContainer>
        <PopUpLocality close={() => this.setPopUpIsOpen(false)} isDisplayed={popUpLocalityIsOpen} />
        {popUpWaitTimeIsOpen && (<PopUpWaitTime />)}
        <Title2>Où se retrouve-t-on ?</Title2>
        <ComponentsContainer>
          {this.renderContactInfos()}
          {isAlreadyCustomer && !isLoggedIn && <Feedback isTransparent feedback={feedbacks[1]} />}
          {(!isAlreadyCustomer || (isAlreadyCustomer && isLoggedIn))
            && (
              <OrderSummaryContainer
                vertical
                clothes={clothes}
                selectedDates={slots}
                canEdit
                deliveryFee={deliveryFee}
                hasZipcode={!!formInputs.zipcode.value && !formInputs.zipcode.error}
                isAdviceOrder={isAdviceOrder}
              />
            )}
        </ComponentsContainer>
        {this.renderButtons()}
        {isPasswordForgotten && (
          <PasswordForgottenModal
            hide={() => this.setState({ isPasswordForgotten: false })}
          />
        )}
      </MeetingInfosContainer>
    );
  }
}

MeetingInfos.propTypes = {
  orderContext: PropTypes.shape({
    clothes: PropTypes.arrayOf(PropTypes.shape({})),
    slots: PropTypes.shape({}),
    setOrderId: PropTypes.func,
    initialize: PropTypes.func,
    updateDeliveryFee: PropTypes.func,
    rdv3ClicksOrder: PropTypes.shape({
      isFurSelected: PropTypes.bool,
      nbMasks: PropTypes.number,
      fabrics: PropTypes.arrayOf(PropTypes.string).isRequired,
      knowHow: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      suitAlterations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired,
    promoCode: PropTypes.shape({
      code: PropTypes.string,
      brand: PropTypes.string,
    }),
    funnelType: PropTypes.string.isRequired,
    nbMasks: PropTypes.number.isRequired,
  }).isRequired,
  customerContext: PropTypes.shape({
    customer: PropTypes.shape({
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      address: PropTypes.shape({}),
      addresses: PropTypes.arrayOf(PropTypes.shape({})),
      hasSubscribedToNewsletter: PropTypes.bool,
      isShadowAccount: PropTypes.bool,
      billingAddress: PropTypes.shape({}),
    }),
    initializeCustomerContext: PropTypes.func,
    setCustomer: PropTypes.func,
    setCustomerDiscountVouchers: PropTypes.func,
    setSelectedAccountCategory: PropTypes.func,
  }).isRequired,
  isAdviceOrder: PropTypes.bool,
  city: PropTypes.string,
};

MeetingInfos.defaultProps = {
  isAdviceOrder: false,
  city: '',
};

export default withCustomerContext(withOrderContext(MeetingInfos));
