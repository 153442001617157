import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import OutInSideClick from '../home/v2/OutInSideClick';
import { mobileThresholdPixels, colors } from '../home/v3/styledComponents';
import { PopUpContainer } from './PopUpWaitTime';

const PopUp = styled(PopUpContainer)`
  background-color: ${colors.popup};
  color: ${colors.white};
  font-family: Roboto;
  font-size: 14px;
  line-height: 20px;
  width: 373px;
  padding: 34px 67px 34px 40px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100vw - 107px);
  }
`;

const PopUpTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 49px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-right: 17px;
  }
`;

const PopUpText = styled.p`
  margin-top: ${(props) => props.marginTop ?? 0}px;
  margin-bottom: 5px;
  ${(props) => props.pointer && 'cursor: pointer;'}
`;

const PopUpLocality = ({ isDisplayed, close }) => (
  <OutInSideClick handleClickOutside={close}>
    <PopUp isDisplayed={isDisplayed}>
      <PopUpTextContainer>
        <PopUpText>
          Oups ! Votre ville n’est pas accessible en métro. Nos couturiers vont devoir se débrouiller autrement.
        </PopUpText>
        Pour les dédommager, les frais de déplacement sont passés à 15€.
      </PopUpTextContainer>
      <PopUpText pointer onClick={close}>OK</PopUpText>
    </PopUp>
  </OutInSideClick>
);

PopUpLocality.propTypes = {
  isDisplayed: PropTypes.bool,
  close: PropTypes.func,
};

PopUpLocality.defaultProps = {
  isDisplayed: false,
  close() {},
};

export default PopUpLocality;
