import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../../layouts/index';
import { Container, Content, StepContainer } from '../../components/home/v3/styledComponents';
import MenuBar from '../../components/home/MenuBar';
import Footer from '../../components/home/v3/Footer';
import MeetingInfos from '../../components/order/MeetingInfos';
import ProgressBar from '../../components/order/ProgressBar';
import BenefitsBanner from '../../components/home/v3/BenefitsBanner';

const Step3 = ({ location }) => (
  <Layout routeSlug="Step3">
    <Container>
      <MenuBar showGiftBanner={false} />
      <Content hasNoBanner>
        <StepContainer smallMarginBottom>
          <ProgressBar step={3} />
          <MeetingInfos isAdviceOrder={location?.state?.isAdviceOrder} city={location?.state?.city} />
        </StepContainer>
      </Content>
      <BenefitsBanner backgroundColor="linen" color="navy" />
      <Footer />
    </Container>
  </Layout>
);

Step3.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      isAdviceOrder: PropTypes.bool,
      city: PropTypes.string,
    }),
  }),
};

Step3.defaultProps = {
  location: undefined,
};

export default Step3;
